<div class="container" role="region">
    <div class="poBox position-relative" role="group">
        <h2 class="boldLIght">What's<strong> New</strong></h2>
        <div class="controls" *ngIf="0">
            <button class="youtube-btn clear-btn" role="button" tabindex="0" (click)="playVideo()">
                <i [class.fa-play]="isPuase" [class.fa-pause]="!isPuase" class="fas" style="font-size: 25px;"></i>
                <span class="sr-only">Video Play/Pause</span>
            </button>
        </div>
        <div >
            <div class="videoSlider" aria-label="Carousel label" aria-atomic="false" id="videoSlider-705530888"
                aria-label="Carousel label" role="region">
                <div class="newProduct bigarrow" *ngFor="let youVideo of bannarVideos; let i = index">
                    <div class="poInnerBox">
                        <div class="poCardwrap__Fullwidth d-flex align-items-center justify-content-center bg-black"  [ngStyle]="{ 'height': imageHeight }" >
                            <iframe class="frameVideo" id="videoPlayer-{{ i }}" [src]="youVideo.url | safe" allowfullscreen
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation"  
                                alt="dell product video"></iframe>
                        </div>
                    </div>
                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="poBox pobox30 position-relative"  role="group" >
        <ng-container *ngIf="bannerSection.length > 0; else loading">
        <h2 class="boldLIght"><strong style="padding-left: 0;font-weight: 500;">Offers</strong></h2>
        <div class="d-flex toggleOfferDiv" *ngIf="bannerSection.length > 1">
            <button id="toggleOffer" class="toggle-button border-none" role="button" tabindex="0">
                <i [class.fa-play]="!toggleOffer" [class.fa-pause]="toggleOffer" class="fas"></i>
                <span class="sr-only">Auto scroll Play/Pause</span>
            </button>
        </div>
        
        <div class="newProduct bigarrow" role="region">
            <div class="poInnerBoxImg">
                <div class="poCardwrap offerBanner">
                    <div class="reviewSlider d-flex align-items-center justify-content-center" aria-label="Offers Silder label" aria-atomic="false"
                        id="reviewSlider-705530888" aria-label="Offers label">
                        <div class="reviewItem offerBanner" role="group" *ngFor="let offer of bannerSection; let i = index"
                            [attr.aria-live]="'slide ' + (i + 1) + ' of ' + bannerSection.length"
                            [attr.aria-label]="'slide ' + (i + 1) + ' of ' + bannerSection.length">
                            <a [href]="offer.redirectUrl" queryParamsHandling="merge">
                                <img class="reviewSlider-image" src="{{offer.url}}" alt="offer-{{i + 1}}" />
                                <span class="sr-only">{{offer.desc}}</span>
                            </a>
                        </div>
                    </div>
                </div>
        
            </div>
            <!-- <div class="swiper-scrollbar"></div> -->
        </div>
    </ng-container>
    <ng-template #loading>
   <div class="skeleton-loader-1"> </div>
   </ng-template>
    </div>
    <div class="poBox pobox30riev position-relative" role="group">
        <ng-container *ngIf="reviews.length > 0; else loading">
        <h2 class="boldLIght">Our <strong>Store</strong></h2>
        <div class="newProduct bigarrow ourstoreslid" role="region">
            <div class="poInnerBox">
                <div class="poCardwrap wrapReviewSlider" [ngStyle]="{ 'height': imageHeight }">
                    <div>
                        <h3>Dell Stores Rating</h3>
                        <p>Average rating of all our stores</p>
                        <div class="starRatingwrap">
                            <img src="./assets/img/rednewstar.svg" alt=""> <small
                                style="margin-top: 2px;font-weight: 800;color: #7F234F !important;">{{reviews[0].averate_rating}}</small>
                        </div>
                    </div>
                    <div class="reviewSliderNew">
                        <div class="d-flex justify-content-between">
                            <h3>Customer Speak</h3>
                            <div class="d-flex">
                                <button id="toggleAutoplay" class="toggle-button border-none" role="button"
                                    tabindex="0">
                                    <i [class.fa-play]="!isAutoplay" [class.fa-pause]="isAutoplay" class="fas"></i>
                                    <span class="sr-only">Auto scroll Play/Pause</span>
                                </button>
                            </div>

                        </div>

                        <div class="rsWrap" aria-label="Review Silder label" aria-atomic="false" id="rsWrap-705530888"
                            aria-label="Carousel label" role="region">
                            <div *ngFor="let review of reviews; let i = index" class="rsSlide"
                                [attr.aria-live]="'slide ' + (i + 1) + ' of ' + reviews.length"
                                [attr.aria-label]="'slide ' + (i + 1) + ' of ' + reviews.length">
                                <div class="rsTop">
                                    <i class="profilePhoto">
                                        <img src="assets/img/avatar.png" alt="profile-avatar">
                                    </i>
                                    <div class="rsDetail">
                                        <h4>{{review.display_Name}}</h4>
                                        <h5>{{review.crt_Dt | date : 'dd MMM yyyy'}}</h5>
                                    </div>
                                    <abbr class="cstReview" style="color: #7F234F;">
                                        <img src="assets/img/singleStarred.svg" alt="rating-icon">
                                        {{review.star_Rating}}
                                    </abbr>
                                </div>
                                <div class="custrevText">
                                    <p>{{review.comment}}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="swiper-scrollbar"></div>
        </div>

    </ng-container>
    <ng-template #loading>
        <div class="skeleton-loader-1"> </div>
    </ng-template>
    </div>
</div>

