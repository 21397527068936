<div id="overlay" class="overlay">

</div>
<app-loading *ngIf="isLoad"></app-loading>
<app-header *ngIf="hfShow" ></app-header>
<!-- desktop version for chatbot -->
<div class="d-flex" id="chat-bot-section" *ngIf="isElementVisible">
    <div class="get-touch-div" *ngIf="open">
        <button class="clear-btn" role="button" id="getInTouch" tabindex="0"  
         matTooltip="Click here to Chat with us!"
        aria-label="Button that displays a tooltip when focused or hovered over" (click)="openChatDiv()">
        <span>
            <i class="fa fa-arrow-right"></i>
        </span>
       
        </button>
        <button class="get-in-touch right-move" (click)="popup(true)" tabindex="0" role="button">
            <img class="img-down" src="assets/img/mingcute_down-line.svg" alt="">
            <span class="get-in-touch-text">Get in Touch</span>
            <img  class="img-hand"  src="assets/img/Layer_1.svg" alt="">
        </button>
    </div>
    <button tabindex="0"  *ngIf="showButtons" role="button" class="float-left whatsapp clear-btn right-move-1">
        <img src="assets/img/whatsapp.svg"  (click)="openWhatsApp()"  width="60" alt="">
    </button>
    <div *ngIf="showButtons" class="float-left chat-box right-move-1" #chatbox>
        <button class="clear-btn close-img" (click)="onMouseLeave()" role="button" #closeIcon>
            <img src="../assets/img/Group 11.svg" alt="close">
        </button>
       
      <p class="chat-text" #chattext>
        Hi, Chat with us to know more latest offers....
      </p>
         
        <img class="chat-img" (click)="openChat()" width="60" (mouseenter)="onMouseEnter()" src="assets/dds-images/_button_icon.svg" alt="">
    </div>
</div>
<main>
    <router-outlet></router-outlet>
    <section *ngIf="hfShow" class="productOffer">
        <app-section></app-section>
    </section>
    <app-store-locator-form (popUp)="popUp($event)" *ngIf="formPopup"></app-store-locator-form>
</main>
<app-footer *ngIf="hfShow"></app-footer>

<!-- mobile version for chatbot -->
<div class="footer-bots" [ngStyle]="{'justify-content': !open ? 'space-around' : 'space-between'}">
    <div class="sub" (click)="popup(true)" *ngIf="open">
       <img src="assets/img/Frame 1.svg" alt="">
        <span>Get in touch</span>
    </div>
    <div class="sub" (click)="openChat()">
      <img src="assets/img/chat-rounded-corners 1.svg" alt="">
      <span>Chat with us</span>
   </div>
   <div class="sub">
      <img src="assets/img/logos_whatsapp-icon.svg" (click)="openWhatsApp()" alt="">
      <span>Whatsapp</span>
   </div>
</div>