import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { GEOLOCATION_SUPPORT, GeolocationService } from '@ng-web-apis/geolocation';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { SharedInfoService } from '../../sharedInfo.service';
import { CITY } from '../models/mainContent';
declare const $: any;


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, AfterViewInit {

  @Input() bannerInput: any;
  mainContent: any;
  widthSize: any;
  topbanner: boolean = true;
  currretCity: string = ""
  findBtn: boolean = true;
  searchValue: string = '';
  newcity!: CITY[];
  placeId: string = "";
  show = true;
  pincode: string = "";
  searchLocation: string = "";
  // popular cities latitude and longitude
  city: CITY[] = [
    {
      "city": "Mumbai",
      "lat": 19.076,
      "lng": 72.8777
    },
    {
      "city": "Bangalore",
      "lat": 12.9716,
      "lng": 77.5946
    },
    {
      "city": "Kolkata",
      "lat": 22.5726,
      "lng": 88.3639
    },
    {
      "city": "Delhi",
      "lat": 28.7041,
      "lng": 77.1025
    },
    {
      "city": "Hyderabad",
      "lat": 17.385,
      "lng": 78.4867
    },
    {
      "city": "Chennai",
      "lat": 13.0827,
      "lng": 80.2707
    }
  ]

  cities = [
    { name: 'Kolkata', class: 'Kolkata' },
    { name: 'Mumbai', class: 'mumbai' },
    { name: 'Delhi', class: 'delhi' },
    { name: 'Bangalore', class: 'banglore' },
    { name: 'Hyderabad', class: 'Hyderabad' },
    { name: 'Chennai', class: 'Chennai' }
  ];
  storeList: any;
  isLoading: boolean = false;


  constructor(
    private readonly geolocation$: GeolocationService,
    private sharedInfoService: SharedInfoService,
    public app: AppComponent,
    private api: ApiService,
    public util: UtilitiesService,
    private breakpointObserver: BreakpointObserver
  ) {
    
    if ((this.app.latitude != undefined && this.app.longitude != undefined) || (this.app.placeId.length > 0)) {
        this.topbanner = false;
        if(this.app.placeId.length > 0){
          this.getstoreListOnLocationOffPlaceId()
        }else{
          this.getstoreListOnLocationOff(); 
        }
      
    }else{
      this.locationAllowPopup()
    }

  }

  ngOnInit(): void {
    this.sharedInfoService.currentSocialMedia.subscribe((data: any) => {
      this.mainContent = data.main;
    });

  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      function stopScroll(e: any) {
        switch (e.keyCode) {
          case 37:
          case 39:
          case 38:
          case 40: // Arrow keys
          case 32:
            e.preventDefault();
            break; // Space
          default:
            break; // do not block other keys
        }
      }
      $("#myUL").on("mouseover", function (e: any) {
        //stuff to do on mouseover
        var selected = $("#myUL .selected");
        selected.removeClass("selected");
        $(e.target).addClass("selected");
        $(e.target).focus();
      });

      $("#myUL").keydown(function (e: any) {
        var selected = $("#myUL .selected");

        selected.removeClass("selected");
        if (e.keyCode == 38) {
          // up
          if (selected.prev().length == 0) {
            $("#myUL li:last").addClass("selected");
          } else {
            selected.prev().addClass("selected");
          }
        }
        if (e.keyCode == 40) {
          // down
          if (selected.next().length == 0) {
            $("#myUL li:first").addClass("selected");
          } else {
            selected.next().addClass("selected");
          }
        }
        if (
          (e.keyCode >= 48 && e.keyCode <= 57) ||
          (e.keyCode >= 65 && e.keyCode <= 90) ||
          e.keyCode == 8
        ) {
          $(".mtmInput").focus();
        } else {
          $("#myUL").find(".selected")[0].focus();
        }
      });

      $("body").on("focus", "#myUL .selected", function (e: any) {

        window.addEventListener("keydown", stopScroll);
      });

      $("body:not(.mtmInput)").on("click", function (e: any) {

        if (!$('.mtmInput').is(":focus")) {
          $("#myUL").hide();
          $(".mainBanner1 .container.mobileP0").css("z-index", "1")
        }
        // window.removeEventListener("keydown");
      });
      $(".mtmInput").on("focusin", function (e: any) {
        $("#myUL").show();
        $(".mainBanner1 .container.mobileP0").css("z-index", "auto")
      });
      $(".mtmInput").keydown(function (e: any) {
        if (e.keyCode == 38 || e.keyCode == 40) {
          $("#myUL li:first").addClass("selected");
          // $("#myUL").find(".selected")[0].focus();
        }
      });


      $(document).keydown(function (e: any) {

        if ($('.mnu-item:focus').length == 1) {
          if (e.which !== 38 && e.which !== 40) {
            $(".mtmInput").focus()
          }
        }
        var selected = $('.mnu-item:focus').index();
        var next;
        var pre;
 

        next = selected + 1;
        pre = selected - 1;
        if (pre < 0)
          pre = 0;
        if (next > $('.mnu-item').length)
          next = $('.mnu-item').length;

        if (e.which == 38) { // up
          $('.mnu-item').eq(pre).focus();
        }
        if (e.which == 40) { // down
          $('.mnu-item').eq(next).focus();
        }
        if (next > 0) {
          if (e.which == 38) {
            // document.body.style.overflow = "scroll";

            e.preventDefault();
            return;
          }
        }
        if (next == $('.mnu-item').length) {
          if (e.which == 40) {
            //  document.body.style.overflow = "scroll";
            e.preventDefault();
            return;
          }

        }

      });

    });


    this.breakpointObserver.observe([
      "(max-width: 786px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.show = true;
        $(document).ready(function () {
          $(".plList").slick({
            infinite: false,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [{
              breakpoint: 700,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,

                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,

                dots: true,
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,

                dots: true,
              },
            },
            {
              breakpoint: 365,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,

                dots: true,
              },
            },
            ],
          });
        })

      } else {
        this.show = true;
      }
    });
  }

  getFocusOnStoreListing(){
    setTimeout(()=>{
      $(".pageHead").focus();
    }, 800)
  }

  locateMe(e: any, value: string) {
    e.preventDefault()
    this.topbanner = false;
    this.searchValue = "";
    this.getFocusOnStoreListing();
    if (value == 'currentLocation') {
      
      if (!this.app.locationOffOn) {
        this.api.getLocationByIp().subscribe((data) => {
          this.currretCity = this.app.currentCity = data.city;
          this.app.latitude = data.lat;
          this.app.longitude = data.lon;
          this.getstoreListOnLocationOff();
          
        })

      } else {
        this.app.locationOn.next(true);
        this.getstoreListOnLocationOff();
      }
      
    } else {
      this.newcity = this.city.filter(x => x.city == value);
      this.currretCity = this.app.currentCity = value;
      this.app.latitude = this.newcity[0].lat;
      this.app.longitude = this.newcity[0].lng;
      this.getstoreListOnLocationOff()
    }
  }


  // toggle stores/service center
  searchType(value: string): any {
    this.app.searchType = value;
    this.searchValue = "";
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let page = 0;
    let record = this.app.record;
    let type = value;

    if (this.placeId.length < 1) {

      this.api.getCityByLatLng(this.app.latitude, this.app.longitude).subscribe((data) => {
        var results = data.results;
        if (data.status === 'OK') {
          if (results[0]) {
            var address_components = results[0].address_components;
            address_components.forEach((element: any) => {
              if (element.types[0] === "locality" && element.types[1] === "political") {
                this.currretCity = this.app.currentCity = element.long_name;
              }
            });
          }
  
        }
      })
    }
    if (this.app.latitude != undefined || this.app.longitude != undefined) {
      if (this.placeId.length > 1) {
        this.util._loading.next(true)
        this.sharedInfoService.storeList.emit(undefined);
        this.api.getStoreDetailsByPlaceId(tenant, this.placeId, distance, record, type, page).subscribe((data) => {
          this.sharedInfoService.storeList.emit(data);
          this.sharedInfoService.page.next(1);

          this.util._loading.next(false)
        }, (err) => {
          this.util._loading.next(false)
        });
      } else {

        this.util._loading.next(true);
        this.sharedInfoService.storeList.emit(undefined);
        this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
          this.sharedInfoService.storeList.emit(data);
          this.sharedInfoService.page.next(1);

          this.util._loading.next(false)
        }, (err) => {
          this.util._loading.next(false)
        });
      }

    }
  }


  locationAllowPopup() {
    if(this.app.placeId.length == 0){
    this.geolocation$.pipe(take(1)).subscribe((position) => {
      this.app.latitude = position.coords.latitude;
      this.app.longitude = position.coords.longitude;
      this.topbanner = false;
      this.app.locationOn.next(true);
      this.api.getReviews(this.app.latitude, this.app.longitude).subscribe( data =>{
        this.api.reviews.next(data);
      })
      this.api.getCityByLatLng(this.app.latitude, this.app.longitude).subscribe((data) => {
        var results = data.results;
        if (data.status === 'OK') {
          if (results[0]) {
            var address_components = results[0].address_components;
            address_components.forEach((element: any) => {
              if (element.types[0] === "locality" && element.types[1] === "political") {
                this.currretCity = this.app.currentCity = element.long_name;
              }
            });
          }
        }
    
      })
    }, err=>{
      this.api.getLocationByIp().subscribe((data) => {
        this.currretCity = this.app.currentCity = data.city;
        this.app.latitude = data.lat;
        this.app.longitude = data.lon;
        if(this.app.reviewsCount == 0){
          this.api.getReviews(data.lat, data.lon).subscribe( data =>{
            this.api.reviews.next(data);
          })
        }  
      })
    });
  }
  }

  enableFindStoreBtn(value: string) {
    this.searchValue = value;
    this.autoSearch();
  }

  enableFindStoreBtn1(ev: any, searcText: string) {
    if (searcText.length > 2) {
      this.findBtn = false;
    } else {
      this.findBtn = true;
      return;
    }
    if (ev.keyCode === 13 || ev.which === 13) {
      this.enableFindStoreBtn(searcText)
    }
    // this.loadPixelScript();
  }


  autoSearch() {
    let search = this.searchValue;
    this.app.getDataLayerOnSearch(search);
    let tenantId = this.app.tenantId;
    $(".mainBanner1 .container.mobileP0").css("z-index", "auto");
    this.util._loading.next(true);
    this.api.autoSearch(search, tenantId).subscribe((data) => {
      // $("#myUL").focus()
      $(".mainBanner1 .container.mobileP0").css("z-index", "auto");
      this.util._loading.next(false);
      this.storeList = data;
      setTimeout(()=>{
        $("#myUL li:first").focus();
      }, 100)



    },
      (err) => {
        this.util._loading.next(false);
      })
  }

  currentLocationStores(value: any) {
    this.searchValue = value.description;
    this.app.loadPixelScript("",this.searchValue);
    let first = value.description.split(",");
    this.currretCity = this.app.currentCity = first[0];
    this.storeList = [];
    let tenant = this.app.tenantId;
    this.placeId = value.place_id;
    this.app.placeId = value.place_id;
    let distance = this.app.distance;
    let record = this.app.record;
    let page = 0;
    let type = this.app.searchType;

    this.util._loading.next(true);
    this.sharedInfoService.storeList.emit(undefined);
    this.api.getStoreDetailsByPlaceId(tenant, this.placeId, distance, record, type, page).subscribe((data) => {
      this.util._loading.next(false);
      this.sharedInfoService.storeList.emit(data);
      this.sharedInfoService.page.next(1);
    }, (err) => {
      this.util._loading.next(false)
    });

  }

  
  getstoreListOnLocationOff() {
    let tenant = this.app.tenantId;
    let lat = this.app.latitude;
    let lang = this.app.longitude;
    let distance = this.app.distance;
    let record = this.app.record;
    let page = 0;
    let type = this.app.searchType;
    this.util._loading.next(true)
    this.sharedInfoService.storeList.emit(undefined);
    this.app.reviewsCount++;
    this.api.getCityByLatLng(this.app.latitude, this.app.longitude).subscribe((data) => {
      var results = data.results;
      if (data.status === 'OK') {
        if (results[0]) {
          var address_components = results[0].address_components;
          address_components.forEach((element: any) => {
            if (element.types[0] === "locality" && element.types[1] === "political") {
              this.currretCity = this.app.currentCity = element.long_name;
            }
          });
        }
      }
    })
    this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
      this.util._loading.next(false)
      this.sharedInfoService.storeList.emit(data);
      this.sharedInfoService.page.next(1);
    }, (err) => {
      this.util._loading.next(false)
    });
  }

  getstoreListOnLocationOffPlaceId(){
    let tenant = this.app.tenantId;
    let distance = this.app.distance;
    let record = this.app.record;
    let page = 0;
    let type = this.app.searchType;
    this.util._loading.next(true)
    this.sharedInfoService.storeList.emit(undefined);
 
    this.api.getStoreDetailsByPlaceId(tenant, this.app.placeId, distance, record, type, page).subscribe((data) => {
      this.util._loading.next(false)
      this.sharedInfoService.storeList.emit(data);
      this.sharedInfoService.page.next(1);
    }, (err) => {
      this.util._loading.next(false)
    });

  }

  
}
