<section class="leadFormSection">
    <div class="form-wrapper">
        <div class="form-container">
            <h2 class="boldLIght towLineHead">
                <strong><span class="f-32">Get In Touch</span></strong>
            </h2>

            <form [formGroup]="enquiryLeadForm">
                <ul>
                    <li>
                        <div class="dds__form__field">
                            <div class="dds__input-text__container">
                                <div class="dds__input-text__wrapper">
                                    <input type="text" placeholder="Enter your name*" formControlName="name"
                                        maxlength="100" autocomplete="name" class="dds__input-text" id="name-input"
                                        required />
                                    <div class="input-error"
                                        *ngIf="submitted && enquiryLeadForm &&  myForm.name.errors?.required">Please
                                        enter your Name</div>
                                    <div class="input-error" *ngIf="submitted && myForm.name.errors?.minlength">
                                        Name must be at least 2 characters long
                                    </div>
                                    <div class="input-error" *ngIf="submitted && myForm.name.errors?.pattern">
                                        Please enter a valid name
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="dds__form__field">
                            <div class="dds__input-text__container">
                                <div class="dds__input-text__wrapper">
                                    <input type="email" placeholder="Enter your email" formControlName="email"
                                        maxlength="100" class="dds__input-text" id="email-input" />
                                    <div class="input-error" *ngIf="submitted && myForm.email.errors?.required">Please
                                        enter
                                        email</div>
                                    <div class="input-error" *ngIf="submitted && myForm.email.errors?.pattern">
                                        Please enter a valid email
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dds__form__field">
                            <div id="text-input-container-8086361770" class="dds__input-text__container">
                                <div class="dds__input-text__wrapper">
                                    <input type="text" placeholder="+91" tabindex="-1" readonly class="mobile-readonly">
                                    <input type="text" style="padding-left: 90px;" class="dds__input-text"
                                        autocomplete="mobile" name="text-input-control-name-744104558"
                                        id="mobile-input-control-744104558" placeholder="Enter your mobile number*"
                                        (input)="utils.maxLength($event, 10)" maxlength="10" formControlName="mobileNo"
                                        inputmode="numeric" 
                                        aria-labelledby="text-input-label-744104558 text-input-helper-744104558" required/>
                                    <div class="input-error" aria-labelledby="mobile-input-control-744104558"
                                        *ngIf="submitted && myForm.mobileNo.errors?.required">
                                        Please enter your Mobile number</div>
                                    <div *ngIf="submitted && myForm.mobileNo.errors?.minlength" class="input-error"
                                        aria-labelledby="text-input-control-808636159">
                                        Please enter 10 digit Mobile Number
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                   
                    <li>
                        <div class="dds__form__field">
                            <div class="dds__input-text__container">
                                <div class="dds__input-text__wrapper">
                                    <input type="text" placeholder="Enter your Pincode*" formControlName="pincode"
                                        maxlength="6" inputmode="numeric" (keypress)="numberOnly($event)"
                                        class="dds__input-text" id="pincode-input" />
                                    <div class="input-error" *ngIf="submitted && myForm.pincode.errors?.required">
                                        Please enter your Pincode
                                    </div>
                                    <div class="input-error" *ngIf="submitted && myForm.pincode.errors?.minlength">
                                        Please enter a 6-digit Pincode
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="input-group">
                            <input type="text" value="Store" class="form-control text-center h-48 no-disabled-style"
                                style="max-width: 80px;" disabled />
                            <input type="text" [placeholder]="storeName" class="form-control h-48 no-disabled-style"
                                disabled />
                        </div>
                    </li>

                    <p class="disclaimer2" [innerHTML]="consentText"></p>
                    <li>
                        <div style="display: flex;flex-direction: row;margin:20px 0px;">
                            <input type="checkbox" tabindex="0" id="checkbox-control-75214377329" checkBoxRef
                                name="checkbox-control-name-75214377329" class="dds__checkbox__input"
                                formControlName="consent" class="checkbox2 form-check-input">
                            <span class="confrim-check"><sup></sup>I would also like to recieve a text message (SMS)
                                with the location of the nearest store.</span>
                        </div>
                        <small class="consent-error" *ngIf="submitted && myForm.consent.errors?.required">
                            <sup>*</sup>Please provide your consent before submitting the form.
                        </small>
                    </li>

                    <li>
                        <button type="button" (click)="onSubmit($event)" style="text-align: center;">
                            {{ submitText }}
                        </button>
                    </li>
                </ul>
            </form>

        </div>
    </div>
    <div class="carousel-container">
        <section id="bannerCarousel">
        </section>
        <div class="dots-arrows-container">
            <button id="bannerAutoPlay-123" (click)="bannerAutoPlayFun()" matTooltip="Auto play/pause for Main Banners!"
                aria-label="Button that displays a tooltip when focused or hovered over" class="pause-button"
                role="button" tabindex="0">
                <i [class.fa-play]="!bannerAutoPlay" [class.fa-pause]="bannerAutoPlay" class="fas"></i>
                <span *ngIf="bannerAutoPlay">Pause</span>
                <span *ngIf="!bannerAutoPlay">Play</span>
            </button>
        </div>
    </div>
</section>

<section class="storeDetailWrap" style="padding-top: 60px;">
    <div class="container">
        <div class="storeDetail">
            <div class="sotreListwrap" *ngIf="storeImages && storeImages.length > 0">
                <h2 class="boldLIght towLineHead">
                    <strong><span class="f-32">Store</span></strong> The best way to buy the products you love.
                </h2>
                <ul class="storeList">
                    <li *ngFor="let store of storeImages" class="li">
                        <img [src]="store.src" alt="" style="margin-bottom: 15px;" />
                        <h2>{{ store.heading }}</h2>
                        <p>{{ store.text }}</p>
                        <span *ngIf="store.price" [innerHTML]="store.price"></span>
                        <div class="btnwrap">
                            <a (click)="app.getDataLayervalues(store, 'Get_in_touch_product')"
                                [href]="getMobileNumber(storDetails,store.vfn)"
                                [ngClass]="utils.btnClass('primary', false)" class="mt-2 w-100">Get in
                                Touch</a>
                        </div>
                    </li>

                </ul>
                <div id="slider_dots" class="slider_dots">
                    <div *ngFor="let store of storeImages" class="slider_navigators"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="thankYoumsg">
        <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        <h3 class="modal-title" id="modal-basic-title">Thank You</h3>

    </div>
    <div class="modal-body text-center">
        <p><strong>{{description}}</strong></p>
    </div>
</ng-template>
<ng-template #thankYouModal let-c="close">
    <div class="thankyouModal">
        <div class="modal-header">
            <h4 class="modal-title tq-title">Thank You for reaching out!</h4>
            <img src="assets/img/close.svg" alt="Close" (click)="c()" class="close-btn"/>
        </div>
        <div class="modal-body desc">
            <p>{{description}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c()" style="height: 40px;">Close</button>
        </div>
    </div>
</ng-template>