import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { SharedInfoService } from '../../sharedInfo.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.8)' })),
      ]),
    ])
  ],
})
export class HomeComponent implements AfterViewInit, OnInit, OnDestroy {

  public storeInfo: any;
  mainContent: any;
  page: any;
  show: boolean = false;
  showPopup: boolean = false;
  enquiryForm!: FormGroup;
  requestCallBackForm!: FormGroup;
  description: string = "";
  storeId: any;
  productInterest: any;
  formattedDate: any;
  minDate = new Date();
  consentText: any;

  script1!: HTMLScriptElement;
  script!: HTMLScriptElement;

  constructor(

    private sharedInfoService: SharedInfoService,
    private api: ApiService,
    public app: AppComponent,
    public util: UtilitiesService,
    private meta: Meta,
    private title: Title,
    public dialog: MatDialog,
    private renderer: Renderer2) {

    this.sharedInfoService.storeList.subscribe(data => {
      this.sharedInfoService.currentPage.subscribe(page => {
        this.page = page;
        if (this.page == 1) {
          this.storeInfo = data;
        } else {
          this.storeInfo = [...this.storeInfo, ...data];
        }
      });

    });
    this.minDate.setDate(this.minDate.getDate());
  }
  ngOnInit() {

    this.api.heaerAndFooter.next(true);
    // setTimeout(() => {
    //   if (this.app.leadForm) {
    //     this.show = true;
    //     this.app.leadForm = false;
    //   }
    // }, 4000)
    this.loadTrackingScript();
  }


  bannerAutoPlay: boolean = false;
  banners: any[] = []

  loadTenantConfig() {
    this.api.getTenantContent(this.app.tenantId).subscribe((data) => {
      this.consentText = data.storeLocator.consentText;
      let content = data.storeLocator;
      content.bannerImg.mobile.sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order));
      content.bannerImg.desktop.sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order));

      var banner = $('#banner');
      this.banners = [];
      if (window.innerWidth <= 800) {
        this.banners = content.bannerImg.mobile;
      } else {
        this.banners = content.bannerImg.desktop;
      }

      if (!content.bannerImg.arrows) {
        $(".btnPrev").hide();
        $(".btnNext").hide();
      }
      let count = 0;
      for (var offer of this.banners) {
        var image = $('<img>').attr('src', offer.url).addClass('half-image');
        var anchor = $('<a>').attr('href', offer.ctaUrl).attr('target', '_blank').append(image);
        var linkText =  $('<span>').attr('class','sr-only').text(offer.imgDesc);
        anchor.append(linkText);
        var bannerImage = $('<div>').addClass('banner-img1').attr("aria-live", "slide " + (count + 1) + " of " + this.banners.length)
          .attr("aria-label", "slide " + (count + 1) + " of " + this.banners.length)
          .append(anchor);
        banner.append(bannerImage);
      }

      this.bannerAutoPlay = content.bannerImg.autoplay;
      if (this.banners.length > 1) {
        banner.slick({
          autoplay: this.bannerAutoPlay,
          autoplaySpeed: content.bannerImg.autoplaySpeed,
          infinite: content.bannerImg.infinite,
          speed: content.bannerImg.speed,
          dots: content.bannerImg.dots,
          arrows: content.bannerImg.arrows
        });
      }
    });

  }

  bannerAutoPlayFun() {
    this.bannerAutoPlay = !this.bannerAutoPlay;
    $('#banner').slick('slickSetOption', 'autoplay', this.bannerAutoPlay, true);
  }


  get requestForm() {
    return this.requestCallBackForm.controls;
  }

  removeStoreDetails(eve: boolean) {
    if (!eve) this.showPopup = false;

  }

  ngAfterViewInit(): void {
    this.app.locationOn.subscribe((val) => {
      let tenant = this.app.tenantId;
      let lat = this.app.latitude;
      let lang = this.app.longitude;
      let distance = this.app.distance;
      let record = this.app.record;
      let page = 0;
      let type = this.app.searchType;
      this.app.locationOffOn = val;
      if (this.app.locationOffOn === true) {
        this.util._loading.next(true)
        this.api.getStoreDetails(tenant, lat, lang, distance, record, type, page).subscribe((data) => {
          this.util._loading.next(false)
          this.storeInfo = data;
          this.sharedInfoService.page.next(1);
        }, (err) => {
          this.util._loading.next(false)
        });
      }
    });

    this.meta.updateTag(
      { name: 'description', content: 'Dell India’s official store locator. Here are Dell Exclusive Stores and Dell Reseller Stores. Also, look for Dell Stores near you & get automated directions. Contact the store, Enquire prices, etc.' },
      'name=description'
    )
    this.meta.updateTag({
      name: 'keywords', content: 'Store Locator for Dell'
    }, 'name=keywords')

    this.title.setTitle("Dell India's official store locator - Dell Exclusive Stores");
    this.loadTenantConfig();
  }

  close() {
    this.show = false;
    this.app.leadForm = false;
    this.showPopup = false;
  }



  close1() {

    this.requestCallBackForm.reset();

    this.show = false;
    this.app.leadForm = false;
    this.showPopup = false;

  }

  submitted = false;

  handleOpenPopup(event: any) {

    const { storeId, showPopup } = event;
    this.showPopup = showPopup;
    this.storeId = storeId;

  }



  ngOnDestroy(): void {
    this.app.leadForm = false;
    this.removeTrackingScript();
  }

  loadTrackingScript() {

    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.text = `
      ttd_dom_ready(function() {
        if (typeof TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new TTDUniversalPixelApi();
          universalPixelApi.init("ev5teqd", ["cs2q5nq"], "https://insight.adsrvr.org/track/up");
        }
      });
    `;
    this.renderer.appendChild(document.body, this.script);
  }

  removeTrackingScript() {
    if (this.script) {
      this.renderer.removeChild(document.body, this.script);
    }
  }

}
