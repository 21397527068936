<div class="enqueryForm signuppopup" id="enqueryForm" [formGroup]="enquiryForm" role="dialog" aria-modal="true"
  aria-labelledby="enqueryForm" aria-hidden="true">
  <h2 class="enqueryForm-title">Please Fill in Your Details and We'll Call You Back!</h2>

  <button class="clear-btn close" role="button" tabindex="0" (click)="popup()">
    <img src="assets/img/Vector.svg" alt="close-icon">
  </button>

  <ul class="efListwrap">
    <li>
      <div id="text-input-774547357" class="dds__input-text__container">
        <div class="dds__input-text__wrapper">
          <label id="text-input-label-808636190" for="text-input-control-808636190"
          class="dds__label">Name</label>
          <input type="text" class="dds__input-text" placeholder="Enter Your Name*"
            name="text-input-control-name-74410778" id="text-input-control-744104558" formControlName="name"
            maxlength="100"
            autocomplete="name"
            aria-labelledby="text-input-label-744104558 text-input-helper-744104558" />
          <small class="error" aria-describedby="text-input-control-744104558" *ngIf="submitted && myForm.name.errors?.required">
            <sup>*</sup>Please enter your name
          </small>
          <small class="error"aria-describedby="text-input-control-744104558"  *ngIf="submitted && myForm.name.errors?.minlength">
            <sup>*</sup>Name must be 2 characters long
          </small>
          <small class="error" aria-describedby="text-input-control-744104558" *ngIf="submitted && myForm.name.errors?.pattern">
            <sup>*</sup>Please enter a valid name
          </small>
        </div>
      </div>
    </li>
    <li>
      <div id="text input-774547357" class="dds__input-text__container">
        <label id="text-input-label-808636191" for="text-input-control-808636191"
        class="dds__label">Email</label>
        <div class="dds__input-text__wrapper">
          <input type="text" class="dds__input-text" name="email-744104558" id="email-input-control-744104558"
            placeholder="Enter Your Email"
            autocomplete="email"
            formControlName="email" maxlength="100"
            aria-labelledby="text-email-label-744104558 text-email-helper-744104558" />
          <small class="error" aria-describedby="email-input-control-744104558" *ngIf="submitted && myForm.email.errors?.required">
            <sup>*</sup>Please enter your email
          </small>
          <small class="error" aria-describedby="email-input-control-744104558" *ngIf="submitted && myForm.email.errors?.pattern">
            <sup>*</sup>Please provide email in abc&#64;example.com
          </small>
        </div>
      </div>
    </li>
    <li>
      <div id="text input-774547357" class="dds__input-text__container">
          <label id="text-input-label-808636192" for="text-input-control-808636192"
          class="dds__label">Mobile Number</label>
        <div class="dds__input-text__wrapper position-relative">
        
          <input type="text" placeholder="+91" tabindex="-1" readonly class="mobile-readonly">
          <input type="text" style="padding-left: 50px;" class="dds__input-text"
          autocomplete="mobile"
            name="text-input-control-name-744104778" id="moble-input-control-744104558" 
            (input)="utils.maxLength($event, 10)" maxlength="10" formControlName="mobileNo" inputmode="numeric"
            aria-labelledby="text-input-label-744104558 text-input-helper-744104558" />
          <small class="error" aria-describedby="moble-input-control-744104558" *ngIf="submitted && myForm.mobileNo.errors?.required">
            <sup>*</sup>Please enter your Mobile number
          </small>
          <small class="error" aria-describedby="moble-input-control-744104558"  *ngIf="submitted && myForm.mobileNo.errors?.minlength">
            <sup>*</sup>Please enter 10 digit Mobile Number
          </small>
        </div>
      </div>
    </li>
    <li>
      <div id="text input-774547357" class="dds__input-text__container">
        
        <div class="dds__input-text__wrapper">
          <label id="text-input-label-808636190" for="text-input-control-808636190"
          class="dds__label">Pincode</label>
          <input type="text" class="dds__input-text" name="text-input-control-name-744104787"
          autocomplete="pincode"
            id="pinoiode-input-control-744104558" placeholder="Enter your Pincode*" appOnlynumber formControlName="pincode"
            maxlength="6" autocomplete="pincode" (input)="utils.maxLength($event, 6)" inputmode="numeric"
            aria-labelledby="text-input-label-744104558 text-input-helper-744104558" />
          <small aria-describedby="pinoiode-input-control-744104558" class="error" *ngIf="submitted && myForm.pincode.errors?.required">
            <sup>*</sup>Please enter your Pincode
          </small>
          <small aria-describedby="pinoiode-input-control-744104558" class="error" *ngIf="submitted && myForm.pincode.errors?.minlength">
            <sup>*</sup>Please enter 6 digit Pincode
          </small>
        </div>
      </div>
    </li>
    <li>
      <div id="text input-774547357" class="dds__input-text__container">
        <div class="dds__input-text__wrapper">
          <label id="text-input-label-808636190" for="text-input-control-808636190"
          class="dds__label">Query</label>
          <input type="text" class="dds__input-text" name="text-input-control-name-744104588"
            id="text-input-control-744104558"
            autocomplete="query"
            formControlName="query" placeholder="Type your query here"
            aria-labelledby="text-input-label-744104558 text-input-helper-744104558" />
        </div>
      </div>
    </li>

  </ul>
  <div class="mt-3">
    <span [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}" class="content-text"></span>
  </div>
  <div class="form-check position-relative mt-2">
    <input class="checkbox1 form-check-input" type="checkbox" checkBoxRef id="flexCheckDefault" formControlName="consent" checkBoxRef id="checkbox1">
    <label class="form-check-label" for="flexCheckDefault">
      <em style="font-size: 12px;"> <sup></sup>I would also like to recieve a text message (SMS) with the location of
        the nearest store.</em>
    </label>
    <small class="error consent-error position-relative" style="bottom: 0px;" aria-describedby="flexCheckDefault" *ngIf="submitted && myForm.consent.errors?.required">
      <sup>*</sup>Please provide your consent before submitting the form.
    </small>
  </div>

  <div class="submitBtncheckobx">
    <a href="javascript:void"
    tabindex="0"
    role="0"
    (keydown)="onSubmit($event,content); utils.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')"
    [ngClass]="utils.btnClass('primary', false)" class="w-100" role="button"
      (click)="onSubmit($event,content); utils.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')">{{submitText}}
    </a>

  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="thankYoumsg">
    <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    <h3 class="modal-title" id="modal-basic-title">Thank You</h3>

  </div>
  <div class="modal-body text-center">
    <p><strong>{{description}}</strong></p>
  </div>
</ng-template>