<div id="getInTouch1" [ngClass]="storDetails == null  ? 'requestCallBackPopup' : ''"
    aria-describedby="enqueryForm-title" [attr.role]="storDetails == null ?'dialog' : ''"
    [attr.aria-modal]="storDetails == null ? true : false" aria-labelledby="enqueryForm" aria-hidden="true">

    <div class="enqueryForm">
        <button type="button" class="close" role="button" tabindex="0" (click)="close();"><img
                src="../../assets/dds-images/close.svg" alt="close icon" srcset=""></button>
        <div class="eqFwrap">
            <h2 class="boldLIght towLineHead" id="enqueryForm-title">
                <strong><span class="f-32">Request a call back</span></strong> Please fill in your details below and
                our team
                will get in touch with you.
            </h2>
            <form [formGroup]="requestCallBackForm">
                <ul class="efListwrap">
                    <li>
                        <div class="dds__form__field">
                            <div id="text-input-container-808636159" class="dds__input-text__container">
                                <label id="text-input-label-808636159" for="text-input-control-808636159"
                                    class="dds__label">Name*</label>
                                <div class="dds__input-text__wrapper">
                                    <input placeholder="Enter Name" type="text" class="dds__input-text"
                                        name="text-input-control-name-808636159" id="text-input-control-808636159"
                                        formControlName="name" maxlength="100" autocomplete="name"
                                        aria-labelledby="text-input-label-808636159 text-input-helper-808636159" />
                                    <div class="error" aria-labelledby="text-input-control-808636159"
                                        *ngIf="submitted && requestForm.name.errors?.required">
                                        Enter your name</div>
                                    <div *ngIf="submitted && requestForm.name.errors?.minlength"
                                        aria-labelledby="text-input-control-808636159" class="error">Name must
                                        be 2 characters long</div>
                                    <div class="error" aria-labelledby="text-input-control-808636159"
                                        *ngIf="submitted && requestForm.name.errors?.pattern">Please
                                        enter a
                                        valid name</div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="dds__form__field">
                            <div id="text-input-container-808636160" class="dds__input-text__container">
                                <label id="text-input-label-808636160" for="mobile-input-control-744104558"
                                    class="dds__label dds__label--required">Mobile</label>
                                <div class="dds__input-text__wrapper position-relative">
                                    <input type="text" placeholder="+91" readonly class="mobile-readonly">
                                    <input type="text" style="padding-left: 50px;" class="dds__input-text"
                                        autocomplete="mobile" name="text-input-control-name-744104558"
                                        id="mobile-input-control-744104558" 
                                        (input)="utils.maxLength($event, 10)" maxlength="10" formControlName="mobileNo"
                                        inputmode="numeric"
                                        aria-labelledby="text-input-label-744104558 text-input-helper-744104558" />
                                    <div class="error" aria-labelledby="mobile-input-control-744104558"
                                        *ngIf="submitted && requestForm.mobileNo.errors?.required">
                                        Please enter your Mobile number</div>
                                    <div *ngIf="submitted && requestForm.mobileNo.errors?.minlength" class="error"
                                        aria-labelledby="text-input-control-808636159">
                                        Please enter 10 digit Mobile Number
                                    </div>
                                </div>
                            </div>
                        </div>

                    </li>

                    <li>
                        <div class="dds__form__field">
                            <div id="text-input-container-808636160" class="dds__input-text__container">
                                <label id="text-input-label-808636160" for="pincode-input-control-808636160"
                                    class="dds__label dds__label--required">Pincode</label>
                                <div class="dds__input-text__wrapper">
                                    <input type="text" placeholder="Pincode" appOnlynumber formControlName="pincode"
                                        autocomplete="pincode" maxlength="6" (input)="utils.maxLength($event, 6)"
                                        inputmode="numeric" class="dds__input-text" name="text-input-control-pincode"
                                        id="pincode-input-control-808636160" required="true"
                                        aria-labelledby="text-input-label-808636160 text-input-helper-808636160" />
                                    <div class="error" aria-labelledby="pincode-input-control-808636160"
                                        *ngIf="submitted && requestForm.pincode.errors?.required">
                                        Please enter your Pincode</div>
                                    <div *ngIf="submitted && requestForm.pincode.errors?.minlength" class="error"
                                        aria-labelledby="pincode-input-control-808636160">Please
                                        enter 6 digit Pincode</div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="dds__form__field">
                            <div id="text-input-container-808636180" class="dds__input-text__container">
                                <label id="text-input-label-808636180" for="email-input-control-808636160"
                                    class="dds__label dds__label--required">Email</label>
                                <div class="dds__input-text__wrapper">
                                    <input type="text" placeholder="Enter email" autocomplete="email"
                                        formControlName="email" maxlength="100" class="dds__input-text"
                                        name="text-input-control-pincode-808636180" id="email-input-control-808636160"
                                        aria-labelledby="text-input-label-808636180 text-input-helper-808636160" />
                                    <div id="text-input-error-808636160" class="error"
                                        aria-labelledby="email-input-control-808636160"
                                        *ngIf="submitted && requestForm.email.errors?.required">
                                        Enter your email</div>
                                    <div *ngIf="submitted && requestForm.email.errors?.pattern" class="error"
                                        aria-labelledby="email-input-control-808636160">
                                        Please provide email in abc&#64;example.com</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="efListwrap">
                    <li class="d-flex">
                        <div class="dds__select w-100" data-dds="select" id="select-492601554">
                            <label id="select-label-681764892" for="select-control-681764892"
                                class="dds__label dds__label--required">Product of Interest</label>
                            <div class="dds__select__wrapper">
                                <select id="select-control-681764892" formControlName="productOfInterest"
                                    aria-label="Select label" class="dds__select__field"
                                    aria-describedby="select-helper-681764892">
                                    <option value="" class="dds__select__option--placeholder" selected="true">Product of
                                        Interest</option>
                                    <option [value]="product.name" *ngFor="let product of productInterest">
                                        {{product.name}}</option>
                                </select>
                            </div>
                        </div>
                        <small class="error" aria-labelledby="select-control-681764892"
                            *ngIf="requestForm.productOfInterest.errors?.required && requestForm.productOfInterest.dirty">
                            <sup>*</sup>Please enter date
                        </small>
                    </li>


                    <li>
                        <span>
                            <label id="date-label-681764892"
                                class="dds__label dds__label--required bg-trans">Date</label>
                            <mat-form-field class="mat-aligns">

                                <input matInput [matDatepicker]="picker" formControlName="callDate"
                                    id="date-input-control-808636159" class="date-input" [min]="minDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <small class="error" aria-labelledby="date-input-control-808636159"
                                *ngIf="requestForm.callDate.errors?.required && requestForm.callDate.dirty">
                                <sup>*</sup>Please enter date
                            </small>
                        </span>

                    </li>
                    <li>
                        <span>
                            <label class="dds__label dds__label--required bg-trans"
                                for="time-input-control-808636159">Time</label>
                            <mat-form-field class="mat-aligns">
                                <mat-select formControlName="callTime" class="time-input"
                                    id="time-input-control-808636159">
                                    <mat-option value="09:00 AM - 10:00 AM">09:00 AM - 10:00
                                        AM</mat-option>
                                    <mat-option value="10:00 AM - 11:00 AM">10:00 AM - 11:00
                                        AM</mat-option>
                                    <mat-option value="11:00 AM - 12:00 PM">11:00 AM - 12:00
                                        PM</mat-option>
                                    <mat-option value="12:00 PM - 01:00 PM">12:00 PM - 01:00
                                        PM</mat-option>
                                    <mat-option value="01:00 PM - 02:00 PM">01:00 PM - 02:00
                                        PM</mat-option>
                                    <mat-option value="02:00 PM - 03:00 PM">02:00 PM - 03:00
                                        PM</mat-option>
                                    <mat-option value="03:00 PM - 04:00 PM">03:00 PM - 04:00
                                        PM</mat-option>
                                    <mat-option value="04:00 PM - 05:00 PM">04:00 PM - 05:00
                                        PM</mat-option>
                                    <mat-option value="05:00 PM - 06:00 PM">05:00 PM - 06:00
                                        PM</mat-option>
                                    <mat-option value="06:00 PM - 07:00 PM">06:00 PM - 07:00
                                        PM</mat-option>

                                </mat-select>
                            </mat-form-field>
                            <small class="error" aria-labelledby="time-input-control-808636159"
                                *ngIf="requestForm.callTime.errors?.required && requestForm.callTime.dirty">
                                <sup>*</sup>Please enter a time
                            </small>
                        </span>

                    </li>
                    <li>
                        <button tabindex="0" type="button" class="w-100 mt-35"
                            [disabled]="!this.requestCallBackForm.valid"
                            (click)="onRequestCallSubmit($event,content);app.addImagePixel('https://insight.adsrvr.org/track/pxl/?adv=ev5teqd&ct=0:031y6qj&fmt=3')"
                            [ngClass]="utils.btnClass('primary', false)">Submit
                        </button>

                    </li>

                </ul>
                <div class="submitBtncheckobx1">
                    <div [innerHTML]="consentText" [ngStyle]="{'font-size': '12px'}"></div>

                    <div style="display: flow-root;">
                        <input type="checkbox" formControlName="consent" class="checkbox">
                        <span></span>
                        <span style="font-size: 12px;"> <sup></sup>I would like to recieve a text message
                            (SMS) with the location of the nearest store.</span>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>


<ng-template #content let-c="close" let-d="dismiss">
    <div class="thankYoumsg">
        <button class="btn-close thankyou-btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        <p><strong>{{description}}</strong></p>
    </div>
</ng-template>
